import React from "react"
import { H1, H2, SideMenu } from "@fastly/consistently"
import { graphql, Link } from "gatsby"
import LayoutCore from "~templates/layout-core"
import { MDXProvider } from "@mdx-js/react"
import FloatedRSSIcon from "~components/RSSicon"
import { Breadcrumbs, BreadcrumbItem, ComplexHomeText } from "~components/Breadcrumbs/Breadcrumbs"
import FrontmatterPageContext from "~context/FakePageContext"
import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"

import { mainArea } from "~vanilla/layout-default.css"
import { main } from "~vanilla/layout-changelog.css"
import { sideMenu } from "~components/SideMenu/changelog.css"
import { SideMenuChangelog } from "~components/SideMenu/side-menu-changelog"

import Content from "~partials/changes/significant/archive.mdx"

const frontmatter = {
  lang: "en",
  section: "changes",
  section_safe: "changes",
  title: "Archive | Fastly Documentation Changes",
  header: "Significant changes archive",
}

const ChangesSigArchivePage = ({ data }) => {
  let years = {}
  const linkPrefix = "/changes/significant"

  data.changelogs.nodes.forEach((post) => {
    let m = post.slug.match(/\/((([0-9]{4})-([0-9]{2})-([0-9]{2}))-(.+))/)
    if (!m) return

    /*
      0: "/2021-09-01-changes."
      1: "2021-09-01-changes"
      2: "2021-09-01"
      3: "2021"
      4: "09"
      5: "01"
      6: "changes"
    */

    let dateAndSlug = m[1]
    // let dateOnly = m[2]
    let dY = m[3]
    let dM = m[4]
    let dD = m[5]
    let pslug = m[6]

    // construct the url from the parts
    post.url = `${linkPrefix}/${dY}/${dM}/${dD}/${pslug}`

    if (!(dY in years)) {
      years[dY] = {}
    }
    years[dY][dateAndSlug] = [post.url, post.frontmatter.header]
  })

  let yearsInRightOrder = Object.keys(years)
    .sort()
    .reverse()

  const ChangelogHeadersByYear = yearsInRightOrder.map((year) => {
    const logs = years[year]
    return (
      <>
        <H2>{year}</H2>
        <ul key={year}>
          {Object.entries(logs).map((x) => {
            const [dateAndSlug, [url, text]] = x
            return (
              <li key={dateAndSlug}>
                <Link to={url}>{text}</Link>
              </li>
            )
          })}
        </ul>
      </>
    )
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <SideMenu
              className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
              aria-label="Navigation between documents"
              data-swiftype-index="false"
            >
              <SideMenuChangelog />
            </SideMenu>

            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-crumb">
                <Breadcrumbs>
                  <BreadcrumbItem name={<ComplexHomeText />} url="/" />
                  <BreadcrumbItem name="Changes" url="/changes/" />
                  <BreadcrumbItem name="Significant Changes" url="/changes/significant/" />
                  <BreadcrumbItem name="Archive" current />
                </Breadcrumbs>
              </div>

              <div className="content-title">
                <H1 m="0">{frontmatter.header}</H1>
              </div>

              <div className="content-content">
                <FloatedRSSIcon
                  url="/changes/significant/rss.xml"
                  title="Link to RSS feed of recent Significant changelog updates"
                />

                <MDXProvider components={{ Link }}>
                  <Content />
                </MDXProvider>

                <hr />

                {ChangelogHeadersByYear}
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}
export default ChangesSigArchivePage

export const query = graphql`
  query {
    changelogs: allMdx(
      filter: { slug: { regex: "/_changelog_significant//" }, frontmatter: { published: { ne: false } } }
      sort: { order: DESC, fields: slug }
    ) {
      nodes {
        slug
        frontmatter {
          header
          published
        }
      }
    }
  }
`

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <CommonHead />
      <SeoHead frontmatter={frontmatter} {...props} />
      <FavHead />
    </>
  )
}
